import { useContext, useEffect, useCallback } from 'react'

import useLocation from 'hooks/useLocation'

import PopupContext from 'contexts/popup'

import { ImageDataLike } from 'gatsby-plugin-image'

interface PopupData {
  image: {
    src: ImageDataLike
    alt: string
  }
  link: string | null
  delay: number | null
  visibleOnMobile?: boolean
}

const usePopup = ({ image, delay, link, visibleOnMobile }: PopupData) => {
  const { setPopupData } = useContext(PopupContext)

  const pathname = useLocation()

  const showPopup = useCallback(() => {
    if (!sessionStorage.getItem(`${pathname}-popupShown`)) {
      setPopupData({
        visible: true,
        image,
        link,
        delay,
        visibleOnMobile,
      })
      sessionStorage.setItem(`${pathname}-popupShown`, 'true')
    }
  }, [image, link, delay, visibleOnMobile, pathname, setPopupData])

  useEffect(() => {
    if (image.src) {
      setTimeout(showPopup, delay ?? 0)
    }
  }, [image.src, delay, showPopup])
}

export default usePopup
