// node modules
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import FindDealer from 'components/shared/find-dealer'

// types
import type { Point } from 'types/point'

const ZnajdzDealera: React.FC<PageProps<Queries.ZnajdzDealeraPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.finddealer
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !data?.allWpPage || !PAGE_SEO)
    throw new Error("CMS data didn't load properly")

  const SERVICE_POINTS: Point[] =
    data?.allWpPage?.nodes[0].onepage?.dealer
      ?.filter((dealer) => dealer?.dealerName && dealer?.dealerContact)
      ?.map((dealer) => ({
        name: dealer?.dealerName!,
        address: dealer?.dealerContact!,
        openingHours: dealer?.dealerHours?.map((item) => ({
          days: item?.dealerHoursDay!,
          hours: item?.dealerHoursHours!,
        }))!,
        phoneNumber: dealer?.dealerPhoneNumber!,
        routeLink: dealer?.dealerUrl!,
        latitude: Number(dealer?.dealerLat)!,
        longitude: Number(dealer?.dealerLng)!,
        isShowroom: Boolean(dealer?.dealerSalesOffice),
        isServicePoint: Boolean(dealer?.dealerService),
        id: uuidv4(),
        postCode: dealer?.dealerPostCode!,
        city: dealer?.dealerCity!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <FindDealer points={SERVICE_POINTS} page="find-dealer" />
    </Layout>
  )
}

export default ZnajdzDealera

export const query = graphql`
  query ZnajdzDealeraPage {
    wpPage(slug: { regex: "/znajdz-dealera/" }) {
      finddealer {
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    allWpPage(filter: { slug: { regex: "/onepage/" } }) {
      nodes {
        onepage {
          dealer {
            dealerName
            dealerContact
            dealerCity
            dealerPostCode
            dealerHours {
              dealerHoursDay
              dealerHoursHours
            }
            dealerPhoneNumber
            dealerLat
            dealerLng
            dealerUrl
            dealerService
            dealerSalesOffice
            dealerMobileService
          }
        }
      }
    }
  }
`
